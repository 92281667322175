body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(58, 58, 58, 0.6);
}

.App {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  min-height: 100vh;
  background: url('../images/background.png');
}

.title {
  padding-bottom: 32px;
  font-size: 64px;
  text-align: center;
  background: url('../images/copy_texture_1_large.jpg') no-repeat center center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
  user-select: none;
}

a {
  color: rgba(0, 0, 0, 0.65);
}

a:hover {
  color: rgba(0, 0, 0, 1);
}
